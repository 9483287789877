@tailwind base;
@tailwind components;
@tailwind utilities;

/* purgecss start ignore */
html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}

button,
input,
select,
textarea {
  margin: 0;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
img,
video {
  height: auto;
  max-width: 100%;
}
iframe {
  border: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
  text-align: left;
}

h1 {
  line-height: 1.1;
}

h1,
h2 {
  letter-spacing: 1px;
}

h4,
input,
label,
p ul,
li,
blockquote {
  line-height: 1.8;
}

p {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

p:first-of-type {
  margin-top: 0;
}

p:last-of-type {
  margin-bottom: 0;
}

p a {
  color: #0069CC;
}

.fancy {
  background: #00b4ae;
  background: -moz-linear-gradient(left,  #00b4ae 35%, #ffcb05 65%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  #00b4ae 35%,#ffcb05 65%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  #00b4ae 35%,#ffcb05 65%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b4ae', endColorstr='#ffcb05',GradientType=1 ); /* IE6-9 */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-size: 400% 400%;
  -webkit-animation: gradientFade 4s ease infinite;
  -moz-animation: gradientFade 4s ease infinite;
  animation: gradientFade 4s ease infinite;
}

@-webkit-keyframes gradientFade {
    0% {
      background-position: 0% 50%
    }
    50% {
      background-position: 100% 50%
    }
    100% {
      background-position: 0% 50%
    }
}
@-moz-keyframes gradientFade {
    0% {
      background-position: 0% 50%
    }
    50% {
      background-position: 100% 50%
    }
    100% {
      background-position: 0% 50%
    }
}
@keyframes gradientFade {
    0% {
      background-position: 0% 50%
    }
    50% {
      background-position: 100% 50%
    }
    100% {
      background-position: 0% 50%
    }
}


.errMsg {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

.social li a {
  transition: transform 0.15s ease-in-out;
  height: 20px;
  width: 20px;
}

.social li a svg {
  width: 100%;
  height: 20px;
}

.social li a:focus,
.social li a:hover {
  transform: translateY(-0.05rem);
  transition: transform 0.15s ease-in-out;
}

div.mce_inline_error {
  padding: 1rem;
}

.rotate-0 {
  transform: rotate(0deg);
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

/* purgecss end ignore */